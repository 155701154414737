import { Box, Typography } from "@mui/material";
import mobileError from "../assets/mobileErrorImg.png";

export default function MobileErrorPage() {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        flexDirection: "column",
        background: "#16341B",
      }}
    >
      <img src={mobileError} alt="Image" style={{ width: "50vw" }} />
      <Typography
        sx={{
          color: "white",
          fontSize: "1em",
          marginTop: 2,
          marginRight: 3,
          marginLeft: 3,
          textAlign: "center",
          fontFamily: "poppins",
        }}
      >
        Mobile Preview is not available; please open in a TV, desktop, or Web
        browser.
      </Typography>
    </Box>
  );
}
