import { useMemo, useState } from "react";
import bullionStats from "../assets/bullioStats.png";
import MeritLogo from "../assets/MeritLogo.png";
import { Box, Fade, Modal, Typography } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import "../App.css";
import Carousel from "react-material-ui-carousel";
import { useEffect } from "react";
import moment from "moment-timezone";
import io from "socket.io-client";
import {
  allCommodities,
  allNewses,
  findBanners,
  getAdminProfile,
  getGoldPriceNews,
  getLiveValueTypeForDisplay,
  getRatio,
  getSocketUrl,
  getSpread,
} from "../sevice/home";
import SubscriptionExpiringSoon from "../components/SubscriptionExpiringSoon";
import SubscriptionExpired from "../components/SubscriptionExpired";
import MarketClose from "../components/MarketClose";
import Blocked from "../components/Blocked";
let socket;
const socket2 = io(process.env.REACT_APP_BACKEND_URL, { path: "/latest" });
const adminId = process.env.REACT_APP_ADMIN_ID;

const Home = () => {
  const [adminData, setAdminData] = useState();

  const [banners, setBanners] = useState([]);
  const [isMarketClosed, setIsMarketClosed] = useState(false);
  const [openExpireSoon, setOpenExpireSoon] = useState(false);
  const [news, setNews] = useState([]);
  const [commodities, setCommodities] = useState([]);
  const [openSubscriptionExpierd, setOpenSubscriptionExpierd] = useState(false);
  const [displayBidOrBuy, setDisplayBidOrBuy] = useState({
    bidOrBuy: "Bid",
    askOrSell: "Ask",
  });
  const [time, setTime] = useState(new Date());
  const [goldNews, setGoldNews] = useState({});
  const [gold, setGold] = useState({
    cur: { ask: 0, bid: 0, highPrice: 0, lowPrice: 0 },
    pre: { ask: 0, bid: 0, highPrice: 0, lowPrice: 0 },
  });
  const [silver, setSilver] = useState({
    cur: { ask: 0, bid: 0, highPrice: 0, lowPrice: 0 },
    pre: { ask: 0, bid: 0, highPrice: 0, lowPrice: 0 },
  });

  const [render, setRender] = useState(true);
  const [spread, setSpread] = useState({
    goldAskSpread: 0,
    goldBidSpread: 0,
    silverAskSpread: 0,
    silverBidSpread: 0,
    goldLowSpread: 0,
    goldHighSpread: 0,
    silverLowSpread: 0,
    silverHighSpread: 0,
  });

  const [ratio, setRatio] = useState({
    Buyers: "77%",
    chgValue: "+0.370%",
    commodity: "Gold",
  });

  const handleExpireSoonOpen = () => setOpenExpireSoon(true);
  const handleExpireSoonClose = () => setOpenExpireSoon(false);

  useMemo(() => {
    if (socket && render) {
      setRender(false);
      socket.on("connect", () => {});
      socket.on("disconnect", () => {});
      socket.on("connect_error", (err) => {
        console.log(`connect_error due to ${err.message}`);
      });
      socket.on("gold-rate-change", (data) => {
        setGold((e) => {
          return { cur: data.data, pre: e.cur };
        });
      });
      socket.on("silver-rate-change", (data) => {
        setSilver((e) => {
          return { cur: data.data, pre: e.cur };
        });
      });
    }
  }, [socket]);

  useEffect(() => {
    const interval = setInterval(() => {
      const now = moment()?.tz(adminData?.time_zone);
      const dayOfWeek = now?.day();
      const hour = now?.hour();
      if (
        (dayOfWeek >= 1 && dayOfWeek <= 5 && hour >= 1 && hour < 2) || // Monday to Friday, 1AM to 2AM
        (dayOfWeek === 6 && hour >= 1) || // Saturday, 1AM onwards
        dayOfWeek === 0 || // Sunday
        (dayOfWeek === 1 && hour < 2) // Monday, before 2AM
      ) {
        setIsMarketClosed(true);
      } else {
        setIsMarketClosed(false);
      }
    }, 100); // Check every minute
    return () => clearInterval(interval);
  }, [adminData?.time_zone]);

  const commodityCalculation = (
    livePrice,
    spread,
    premium,
    fxRate,
    purity,
    unit,
    weight,
    charge
  ) => {
    let value =
      (Number(livePrice) + Number(spread) + Number(premium)) *
        (fxRate / 31.1035) *
        Number(purity) *
        Number(weight) *
        Number(unit) +
      Number(charge);
    if (value.toFixed(0).length < 5) {
      return value.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    } else {
      return value.toLocaleString("en-US", {
        // minimumFractionDigits: 2,
        maximumFractionDigits: 0,
      });
    }
  };

  useEffect(() => {
    handleFindRatio();
    hadlefetchNews();
    handleFindSpread();
    handleFindAdminProfile();
    handleFindLiveValueTypeForDisplay();
    handleFinsCommodities();
    handleFindSocketUrl();
    handelFindBanners();
    handleFindGoldPriceNews();
  }, []);
  useEffect(() => {
    const timer = setInterval(() => {
      setTime(new Date());
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    socket2.on("connect", () => {});
    socket2.on("disconnect", () => {});
    socket2.emit("join_room", { roomId: adminId });
    socket2.on("commodityRerender", (data) => {
      handleFinsCommodities();
    });
    socket2.on("newsRerender", (data) => {
      hadlefetchNews();
    });
    socket2.on("spreadRerender", (data) => {
      handleFindSpread();
    });

    socket2.on("mediaRerender", (data) => {
      handelFindBanners();
    });
    socket2.on("adminBlockRerender", (data) => {
      window.location.reload();
    });
    socket2.on("socketRerender", (data) => {
      window.location.reload();
    });
    socket2.on("bidAndAskTitleRerender", (data) => {
      handleFindLiveValueTypeForDisplay();
    });
    socket2.on("reloadRender", (data) => {
      window.location.reload();
    });
    socket2.on("upgradeRerender", (data) => {
      handleFindAdminProfile();
    });
  }, []);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     window.location.reload();
  //   }, 2 * 60 * 1000);
  //   return () => clearInterval(interval);
  // }, []);

  const handleFindGoldPriceNews = async () => {
    const res = await getGoldPriceNews();
    if (res.length > 0) setGoldNews(res[0]);
  };

  const handleFindSocketUrl = async () => {
    const res = await getSocketUrl();
    socket = io(res?.socketURL);
  };

  const formatTime = (date) => {
    return date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
  };

  const formatDay = (date) => {
    return date.toLocaleString([], { weekday: "long" });
  };

  const formatDate = () => {
    return moment(new Date()).format("DD MMM YYYY");
  };

  const handleOpenSubscriptionExpierd = () => setOpenSubscriptionExpierd(true);
  const handleCloseSubscriptionExpierd = () =>
    setOpenSubscriptionExpierd(false);

  const handelFindBanners = async () => {
    const res = await findBanners();
    if (Array.isArray(res)) {
      setBanners(res);
    }
  };

  const handleFinsCommodities = async () => {
    const res = await allCommodities();
    if (Array.isArray(res?.commodities)) {
      setCommodities(res.commodities);
    }
  };

  const handleFindSpread = async () => {
    const res = await getSpread();
    if (res) {
      setSpread(res);
    }
  };

  const handleFindRatio = async () => {
    const res = await getRatio();
    if (res?.data) {
      setRatio(res.data);
    }
  };

  const hadlefetchNews = async () => {
    const res = await allNewses();
    if (Array.isArray(res)) {
      setNews(res);
    }
  };

  const handleFindLiveValueTypeForDisplay = async () => {
    const res = await getLiveValueTypeForDisplay();
    if (res) {
      setDisplayBidOrBuy(res);
    }
  };

  ////////////Block//////
  const [openBlocked, setOpenBlocked] = useState(false);
  const handleOpenBlocked = () => setOpenBlocked(true);
  const handleCloseBlocked = () => {
    window.location.reload();
  };

  ////////////////////////////////////////////////////////////
  const handleFindAdminProfile = async () => {
    const res = await getAdminProfile();
    setAdminData(res);
    if (!res.status || res.isBlocked) {
      handleOpenBlocked();
    } else if (!res.isBlocked && openBlocked) {
    } else {
      handlecheckSubscriptionExpierd(res);
    }
  };
  const handlecheckSubscriptionExpierd = (data) => {
    const givenDate = new Date(data?.package.expire_date);
    let a = givenDate.toISOString();
    let b = new Date().toISOString();
    if (a < b) {
      handleOpenSubscriptionExpierd();
    }
  };

  useEffect(() => {
    if (adminData) {
      const interval = setInterval(() => {
        checkSubscriptionExpireSoon(adminData);
      }, 60 * 60 * 1000);
      checkSubscriptionExpireSoon(adminData);
      return () => clearInterval(interval);
    }
  }, [adminData]);

  const checkSubscriptionExpireSoon = (data) => {
    console.log(data);
    const givenDate = new Date(data?.package.expire_date);
    const oneMonthAgo = new Date(givenDate);
    oneMonthAgo.setDate(givenDate.getDate() - 30);
    let a = oneMonthAgo.toISOString();
    let b = new Date().toISOString();
    if (b >= a) {
      const currentDate = new Date();
      const currentHour = currentDate.getHours();
      console.log(currentHour);
      if (currentHour === 10) {
        handleExpireSoonOpen();
      }
    }
  };

  ////////////////////////////////////////////////////////////

  return (
    <div>
      <Box sx={{ height: "100vh" }}>
        {isMarketClosed && <MarketClose timeZone={adminData?.time_zone} />}
        <Box component="section" sx={{ height: "90vh", display: "flex" }}>
          <Box component="left" flex={1}>
            <Box
              sx={{
                my: 3,
                ml: 3,
                p: 3,
                height: "88%",
                background: "#04291B",
                borderRadius: "61px 61px 30px 30px",
                display: "flex",
                flexDirection: "column",
                gap: 3,
              }}
            >
              <Box sx={{ height: "32%" }}>
                <Carousel
                  animation="fade" // Set the animation type to slide
                  navButtonsAlwaysVisible={false} // Show navigation buttons always
                  interval={10000}
                  indicatorContainerProps={{
                    style: {
                      display: "none",
                    },
                  }}
                  sx={{
                    maxHeight: "25vh",
                    borderRadius: "15px",
                    border: "1px solid #C79135",
                    background: "#013220",
                  }}
                  transitionTime={3000} // Adjust the transition time to control the speed of the fade effect
                  autoPlay
                  duration={2000}
                >
                  {banners?.map((banner, i) => (
                    <img
                      style={{
                        width: "100%",
                        height: "25vh",
                        objectFit: "cover",
                      }}
                      src={banner.imageUrl}
                      alt="banner"
                    />
                  ))}
                </Carousel>
              </Box>
              <Box
                sx={{
                  height: "50vh",
                  display: "flex",
                  flexDirection: "column",
                  background: "#013220",
                  border: "1px solid #C79135",
                  borderRadius: "20px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    height: "15%",
                    background: "#C79135",
                    borderTopLeftRadius: "15px",
                    borderTopRightRadius: "15px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "1.4vw",
                      fontWeight: "bold",
                      flexBasis: 0,
                      flexGrow: 1,
                      flexShrink: 1,
                      display: "flex",
                      color: "#04291B",
                      justifyContent: "center",
                    }}
                  >
                    SPOT RATE
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "5px",
                      flexBasis: 0,
                      flexGrow: 1,
                      flexShrink: 1,
                    }}
                  >
                    <Box
                      sx={{
                        color: "#fff",
                        background: "#16341B",
                        px: "7px",
                        display: "inline-block",
                        width: "fit-content",
                        borderRadius: "4px",
                      }}
                    >
                      <Typography
                        style={{ fontSize: "1vw", fontWeight: "bold" }}
                      >
                        $
                      </Typography>
                    </Box>
                    <Typography
                      sx={{
                        fontSize: "1.4vw",
                        color: "#04291B",
                        fontWeight: "bold",
                      }}
                    >
                      {displayBidOrBuy.bidOrBuy}
                    </Typography>
                    <Typography style={{ color: "#04291B", fontSize: "1.2vw" }}>
                      oz
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "5px",
                      flexBasis: 0,
                      flexGrow: 1,
                      flexShrink: 1,
                    }}
                  >
                    <Box
                      sx={{
                        color: "#fff",
                        background: "#16341B",
                        px: "7px",
                        display: "inline-block",
                        width: "fit-content",
                        borderRadius: "4px",
                      }}
                    >
                      <Typography
                        style={{ fontSize: "1vw", fontWeight: "bold" }}
                      >
                        $
                      </Typography>
                    </Box>
                    <Typography
                      sx={{
                        fontSize: "1.4vw",
                        color: "#04291B",
                        fontWeight: "bold",
                      }}
                    >
                      {displayBidOrBuy.askOrSell}
                    </Typography>
                    <Typography style={{ color: "#04291B", fontSize: "1.2vw" }}>
                      oz
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    height: "42%",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "2vw",
                      fontWeight: "bold",
                      marginBottom: 4.3,
                      flexBasis: 0,
                      flexGrow: 1,
                      flexShrink: 1,
                      display: "flex",
                      color: "white",
                      justifyContent: "center",
                    }}
                  >
                    GOLD
                  </Typography>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "0.3rem",
                      alignItems: "center",
                      flexBasis: 0,
                      flexGrow: 1,
                      flexShrink: 1,
                    }}
                  >
                    <Box
                      sx={{
                        marginBottom: "0.3em",
                        transition: "background-color 0.5s ease",
                        fontFamily: "Calibri",
                        fontWeight: "600",
                        fontSize: "2.3vw",
                        padding: "0 0.5em 0 0.5em",
                        border: "1px solid #C79135",
                        borderRadius: "10px",
                        color: "#FFFFFF",
                        background:
                          Number(gold?.cur?.bid).toFixed(2) <
                          Number(gold?.pre?.bid).toFixed(2)
                            ? "#E33D30"
                            : Number(gold?.cur?.bid).toFixed(2) >
                              Number(gold?.pre?.bid).toFixed(2)
                            ? "#2BA817"
                            : "#202123",
                      }}
                    >
                      <Typography sx={{ fontSize: "2vw", fontWeight: "bold" }}>
                        {gold?.cur?.bid
                          ? (
                              Number(spread?.goldBidSpread) +
                              Number(gold?.cur?.bid)
                            ).toFixed(2)
                          : "0.00"}
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        gap: "5px",
                        alignItems: "center",
                        mt: 0.5,
                      }}
                    >
                      <Box
                        sx={{
                          background: "#E33D30",
                          color: "#fff",
                          px: 1,
                          display: "inline-block",
                          width: "fit-content",
                          borderRadius: "3px",
                        }}
                      >
                        <Typography style={{ fontSize: "1vw" }}>Low</Typography>
                      </Box>
                      <Typography style={{ color: "white", fontSize: "1.3vw" }}>
                        {(
                          Number(spread.goldLowSpread) +
                          Number(gold?.cur?.lowPrice)
                        ).toFixed(2)}
                      </Typography>
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "0.3rem",
                      alignItems: "center",
                      flexBasis: 0,
                      flexGrow: 1,
                      flexShrink: 1,
                    }}
                  >
                    <Box
                      sx={{
                        marginBottom: "0.3em",
                        transition: "background-color 0.5s ease",
                        fontFamily: "Calibri",
                        fontWeight: "600",
                        fontSize: "2.3vw",
                        padding: "0 0.5em 0 0.5em",
                        border: "1px solid #C79135",
                        borderRadius: "10px",
                        color: "#FFFFFF",
                        background:
                          Number(gold?.cur?.ask).toFixed(2) <
                          Number(gold?.pre?.ask).toFixed(2)
                            ? "#E33D30"
                            : Number(gold?.cur?.ask).toFixed(2) >
                              Number(gold?.pre?.ask).toFixed(2)
                            ? "#2BA817"
                            : "#202123",
                      }}
                    >
                      <Typography sx={{ fontSize: "2vw", fontWeight: "bold" }}>
                        {gold?.cur?.ask
                          ? (
                              Number(spread?.goldAskSpread) +
                              Number(gold?.cur?.ask)
                            ).toFixed(2)
                          : "0.00"}
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        gap: "5px",
                        mt: 0.5,
                        alignItems: "center",
                      }}
                    >
                      <Box
                        sx={{
                          background: "#2BA817",
                          color: "#fff",
                          px: 1,
                          display: "inline-block",
                          width: "fit-content",
                          borderRadius: "3px",
                        }}
                      >
                        <Typography style={{ fontSize: "1vw" }}>
                          High
                        </Typography>
                      </Box>
                      <Typography style={{ color: "white", fontSize: "1.3vw" }}>
                        {gold?.cur?.highPrice
                          ? (
                              Number(spread.goldHighSpread) +
                              Number(gold?.cur?.highPrice)
                            ).toFixed(2)
                          : "0.00"}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box
                  sx={{
                    height: "42%",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "1.6vw",
                      marginBottom: 4.3,
                      flexBasis: 0,
                      flexGrow: 1,
                      flexShrink: 1,
                      display: "flex",
                      justifyContent: "center",
                      color: "white",
                    }}
                  >
                    SILVER
                  </Typography>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "0.3rem",
                      alignItems: "center",
                      flexBasis: 0,
                      flexGrow: 1,
                      flexShrink: 1,
                    }}
                  >
                    <Box
                      sx={{
                        fontFamily: "Calibri",
                        fontSize: "1.4vw",
                        padding: "0 0.5em 0 0.3em",
                        transition: "background-color 0.5s ease",
                        border: "1px solid #C79135",
                        borderRadius: "10px",
                        color: "#FFFFFF",
                        background:
                          Number(silver?.cur?.bid).toFixed(2) <
                          Number(silver?.pre?.bid).toFixed(2)
                            ? "#E33D30"
                            : Number(silver?.cur?.bid).toFixed(2) >
                              Number(silver?.pre?.bid).toFixed(2)
                            ? "#2BA817"
                            : "#202123",
                      }}
                    >
                      <Typography sx={{ fontSize: "2vw" }}>
                        &nbsp;&nbsp;
                        {silver?.cur?.bid
                          ? (
                              Number(spread?.silverBidSpread) +
                              Number(silver?.cur?.bid)
                            ).toFixed(3)
                          : "0.000"}
                        &nbsp;&nbsp;
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        gap: "5px",
                        alignItems: "center",
                        mt: 0.5,
                        // pl: 1,
                      }}
                    >
                      <Box
                        sx={{
                          background: "#E33D30",
                          color: "#fff",
                          px: 1,
                          display: "inline-block",
                          width: "fit-content",
                          borderRadius: "3px",
                        }}
                      >
                        <Typography style={{ fontSize: "1vw" }}>Low</Typography>
                      </Box>
                      <Typography style={{ color: "white", fontSize: "1vw" }}>
                        {silver?.cur?.lowPrice
                          ? (
                              Number(spread.silverLowSpread) +
                              Number(silver?.cur?.lowPrice)
                            ).toFixed(3)
                          : "0.000"}
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </Typography>
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "0.3rem",
                      alignItems: "center",
                      flexBasis: 0,
                      flexGrow: 1,
                      flexShrink: 1,
                    }}
                  >
                    <Box
                      sx={{
                        fontFamily: "Calibri",
                        fontSize: "1.4vw",
                        padding: "0 0.5em 0 0.3em",
                        transition: "background-color 0.5s ease",
                        border: "1px solid #C79135",
                        borderRadius: "10px",
                        color: "#FFFFFF",
                        background:
                          Number(silver?.cur?.ask).toFixed(2) <
                          Number(silver?.pre?.ask).toFixed(2)
                            ? "#E33D30"
                            : Number(silver?.cur?.ask).toFixed(2) >
                              Number(silver?.pre?.ask).toFixed(2)
                            ? "#2BA817"
                            : "#202123",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "2vw",
                        }}
                      >
                        &nbsp;&nbsp;
                        {silver?.cur?.ask
                          ? (
                              Number(spread?.silverAskSpread) +
                              Number(silver?.cur?.ask)
                            ).toFixed(3)
                          : "0.000"}
                        &nbsp;&nbsp;
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        gap: "5px",
                        alignItems: "center",
                        mt: 0.5,
                        // pl: 1,
                      }}
                    >
                      <Box
                        sx={{
                          background: "#2BA817",
                          color: "#fff",
                          px: 1,
                          display: "inline-block",
                          width: "fit-content",
                          borderRadius: "3px",
                        }}
                      >
                        <Typography style={{ fontSize: "1vw" }}>
                          High
                        </Typography>
                      </Box>
                      <Typography style={{ color: "white", fontSize: "1vw" }}>
                        {silver?.cur?.highPrice
                          ? (
                              Number(spread.silverHighSpread) +
                              Number(silver?.cur?.highPrice)
                            ).toFixed(3)
                          : "0.000"}
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  height: "15%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  borderRadius: "15px",
                  border: "1px solid #C79135",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    mt: 3,
                    px: 5,
                    py: 1,
                    boxSizing: "border-box",
                  }}
                >
                  <Typography sx={{ color: "white", fontSize: "1vw" }}>
                    BUYERS
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "1vw",
                      color:
                        Number(ratio?.chgValue?.replace("%", "")) >= 0
                          ? "#E33D30"
                          : "#E33D30",
                    }}
                  >
                    {ratio?.chgValue}
                  </Typography>
                  <Typography sx={{ color: "white", fontSize: "1vw" }}>
                    SELLERS
                  </Typography>
                </Box>
                <Box sx={{ width: "90%", px: 4 }}>
                  <LinearProgress
                    variant="determinate"
                    value={Number(ratio?.Buyers?.match(/\d+/)[0])}
                    style={{
                      backgroundColor: "#E33D30",
                    }}
                    sx={{
                      "& .MuiLinearProgress-bar": {
                        backgroundColor: "#2FB545",
                      },
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    px: 7,
                    py: 1,
                    boxSizing: "border-box",
                  }}
                >
                  <Typography sx={{ fontSize: "1vw", color: "#2FB545" }}>
                    {ratio?.Buyers}
                  </Typography>
                  <Box sx={{ mt: 1, mb: 3 }}>
                    <a href="https://www.bullionstats.com/">
                      <img
                        style={{ width: "150px", height: "auto" }}
                        src={bullionStats}
                        alt="bullionStats"
                      />
                    </a>
                  </Box>
                  <Typography sx={{ fontSize: "1vw", color: "#E33D30" }}>
                    {100 - Number(ratio?.Buyers?.match(/\d+/)[0])}%
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box component="right" flex={1.5}>
            <Box
              sx={{
                mt: 3,
                mx: 3,
                p: 3,
                height: "88%",
                background: "#04291B",
                borderRadius: "61px 61px 30px 30px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-evenly",
                  mb: 2.5,
                  height: "19vh",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    color: "#202123",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "2vw",
                      // fontWeight: "bold",
                      color: "#C79135",
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                      mb: -1,
                    }}
                  >
                    {formatDay(time)
                      .split("")
                      .map((item, index) => (
                        <span key={index}>{item.toUpperCase()}</span>
                      ))}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "2vw",
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                      color: "#C79135",
                    }}
                  >
                    {formatDate(time)
                      .toUpperCase()
                      .split("")
                      .map((item, index) => (
                        <span key={index}>{item.toUpperCase()}</span>
                      ))}
                  </Typography>
                </Box>
                <Box>
                  <img style={{ width: "15vw" }} src={MeritLogo} alt="" />
                </Box>
                <Box>
                  <Typography
                    sx={{
                      fontSize: "2vw",
                      fontWeight: "bold",
                      color: "#C79135",
                    }}
                  >
                    {formatTime(time).toUpperCase()}
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  height: "60vh",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box
                  sx={{
                    gridArea: "table",
                    boxSizing: "border-box",
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                  }}
                >
                  <Box
                    sx={{
                      gridArea: "tabeHeader",
                      backgroundColor: "#C79135",
                      display: "flex",
                      justifyContent: "center",
                      borderRadius: "15px 15px 0px 0px",
                      alignItems: "center",
                      // height: "10%",
                      color: "#FFFFFF",
                    }}
                  >
                    <Typography
                      sx={{
                        flex: "1.2",
                        display: "flex",
                        justifyContent: "start",
                        fontSize: "1.8vw",
                        fontWeight: "bold",
                        paddingLeft: "30px",
                        color: "#082B1E",
                      }}
                    >
                      COMMODITY
                    </Typography>
                    <Typography
                      sx={{
                        flex: "1",
                        display: "flex",
                        justifyContent: "center",
                        fontSize: "1.8vw",
                        fontWeight: "bold",
                        color: "#082B1E",
                      }}
                    >
                      WEIGHT
                    </Typography>
                    <Typography
                      sx={{
                        flex: "1",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "2vw",
                        fontWeight: "bold",
                        color: "#082B1E",
                      }}
                    >
                      BUY
                      <Typography sx={{ fontSize: "0.9vw", mt: 2.2 }}>
                        &nbsp;AED
                      </Typography>
                    </Typography>
                    <Typography
                      sx={{
                        flex: "1",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "2vw",
                        fontWeight: "bold",
                        color: "#082B1E",
                      }}
                    >
                      SELL
                      <Typography sx={{ fontSize: "0.9vw", mt: 2.2 }}>
                        &nbsp;AED
                      </Typography>
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      gridArea: "tableContent",
                      display: "flex",
                      height: "87%",
                      flexDirection: "column",
                      overflowX: "scroll",
                      overflowX: "hidden",
                      "&::-webkit-scrollbar": {
                        display: "none",
                      },
                    }}
                  >
                    {commodities?.map((commodity, idx) => {
                      const words = commodity?.display_metal
                        ? commodity?.display_metal.split(" ")
                        : commodity?.commodity_title.split(" ");

                      return (
                        <Box
                          key={idx}
                          sx={{
                            flexBasis: 0,
                            minHeight: "16%",
                            flexGrow: 1,
                            gridArea: "tableRow1",
                            backgroundColor: "#013220",
                            display: "flex",
                            maxHeight: commodities.length > 6 ? "16.6%" : "",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "1.3vw",
                              justifyContent: "left",
                              flex: "1.3",
                              display: "flex",
                              alignItems: "center",
                              color: "#FFFFFF",
                              fontSize: "1.3vw",
                              borderBottom: "1px #C79135 solid",
                              borderRight: "1px #C79135 solid",
                              borderLeft: "1px #C79135 solid",
                              color: "#FFFFFF",
                            }}
                          >
                            {words?.map((word, index) => {
                              if (index === 0) {
                                return (
                                  <span
                                    key={index}
                                    style={{
                                      fontSize: "2vw",
                                      paddingLeft: "30px",
                                    }}
                                  >
                                    {word.toUpperCase()}
                                  </span>
                                );
                              } else {
                                return (
                                  <span
                                    key={index}
                                    style={{
                                      fontSize: "1vw",
                                      marginTop: "0.2rem",
                                    }}
                                  >
                                    &nbsp;{word.toUpperCase()}
                                  </span>
                                );
                              }
                            })}
                            &nbsp;
                            {commodity.unitLabel === "TTB"
                              ? ""
                              : commodity.displayPurity}
                          </Typography>
                          <Box
                            sx={{
                              flex: "1",
                              display: "flex",
                              alignItems: "center",
                              fontSize: "2vw",
                              borderBottom: "1px #C79135 solid",
                              borderRight: "1px #C79135 solid",
                              color: "#C79135",
                              justifyContent: "left",
                            }}
                          >
                            <Typography
                              style={{
                                color: "#FFFFFF",
                                fontSize: "2vw",
                                // fontWeight: "bold",
                              }}
                              sx={{
                                paddingLeft: { lg: "3.5vw", md: "3vw" },
                              }}
                            >
                              {commodity.unit} {commodity.unitLabel}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              flex: "1",
                              display: "flex",
                              alignItems: "center",
                              color: "#FFFFFF",
                              // justifyContent: "center",
                              fontSize: "2vw",
                              borderBottom: "1px #C79135 solid",
                              borderRight: "1px #C79135 solid",
                              justifyContent: "left",
                            }}
                          >
                            <Typography
                              style={{
                                fontSize: "2vw",
                                fontWeight: "bold",
                              }}
                              sx={{
                                paddingLeft: { lg: "3.4vw", md: "3vw" },
                              }}
                            >
                              {commodityCalculation(
                                commodity.commodity_title === "Silver"
                                  ? silver?.cur?.bid
                                  : gold?.cur?.bid,
                                commodity.commodity_title === "Silver"
                                  ? spread.silverBidSpread
                                  : spread.goldBidSpread,
                                commodity.buy_premium,
                                3.674,
                                commodity.purity,
                                commodity.unit,
                                commodity.unitLabel === "TTB"
                                  ? 116.64
                                  : commodity.unitLabel === "KG"
                                  ? 1000
                                  : commodity.unitLabel === "OZ"
                                  ? 31.1
                                  : commodity.unitLabel === "TOLA"
                                  ? 11.7
                                  : 1,
                                commodity.buy_charge
                              )}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              flex: "1",
                              display: "flex",
                              alignItems: "center",
                              color: "#FFFFFF",
                              // justifyContent: "center",
                              fontSize: "2vw",
                              borderBottom: "1px #C79135 solid",
                              borderRight: "1px #C79135 solid",
                              justifyContent: "left",
                            }}
                          >
                            <Typography
                              style={{
                                fontSize: "2vw",
                                fontWeight: "bold",
                              }}
                              sx={{
                                paddingLeft: { lg: "3vw", md: "3vw" },
                              }}
                            >
                              {commodityCalculation(
                                commodity.commodity_title === "Silver"
                                  ? silver?.cur?.ask
                                  : gold?.cur?.ask,
                                commodity.commodity_title === "Silver"
                                  ? spread.silverAskSpread
                                  : spread.goldAskSpread,
                                commodity.premium,
                                3.674,
                                commodity.purity,
                                commodity.unit,
                                commodity.unitLabel === "TTB"
                                  ? 116.64
                                  : commodity.unitLabel === "KG"
                                  ? 1000
                                  : commodity.unitLabel === "OZ"
                                  ? 31.1
                                  : commodity.unitLabel === "TOLA"
                                  ? 11.7
                                  : 1,
                                commodity.charges
                              )}
                            </Typography>
                          </Box>
                        </Box>
                      );
                    })}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          component="section"
          sx={{
            height: "10vh",
            background: "#013220",
            mx: 3,
          }}
        >
          <Box
            sx={{
              display: "flex",
              height: "80%",
            }}
          >
            <Box
              sx={{
                color: "#C79135",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                border: "1px solid #C79135",
                borderRadius: "15px 0px 0px 15px",
                background: "#04291B",
                width: "150px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "1.1rem",
                  fontWeight: "bold",
                  textAlign: "center",
                  mt: "8px",
                  mb: "5px",
                  letterSpacing: "5px",
                }}
              >
                MERIT <br />
                <Box
                  sx={{
                    fontSize: "1.1rem",
                    letterSpacing: "5px",
                    marginTop: -1,
                  }}
                >
                  NEWS
                </Box>
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                background: "#04291B",
                width: "100%",
                border: "1px solid #C79135",
                borderRadius: "0px 15px 15px 0px",
              }}
            >
              <marquee behavior="" direction="">
                <Box sx={{ display: "flex", color: "white" }}>
                  {adminData?.isNewsEnable &&
                  Object.keys(goldNews).length > 0 ? (
                    <Box
                      style={{
                        fontSize: "2vw",
                        fontFamily: "poppins",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <span style={{ fontWeight: "bold" }}>
                        Gold Price News
                      </span>
                      &nbsp;&nbsp;:&nbsp;&nbsp;<span>{goldNews.title}</span>
                    </Box>
                  ) : (
                    news?.map((item, index) => {
                      return (
                        <Box
                          key={item._id}
                          style={{
                            fontSize: "1.5vw",
                            fontFamily: "poppins",
                            alignItems: "center",
                            display: "flex",
                          }}
                        >
                          <span style={{ fontWeight: "bold" }}>
                            {item.newsTitle}
                          </span>
                          &nbsp;&nbsp;:&nbsp;&nbsp;
                          <span>{item.newsDetails}</span>
                          <span
                            style={{
                              paddingRight: "50px",
                              paddingLeft: "50px",
                            }}
                          >
                            {index === news.length - 1 ? "" : "|"}
                          </span>
                        </Box>
                      );
                    })
                  )}
                </Box>
              </marquee>
            </Box>
          </Box>
        </Box>
      </Box>
      <Modal
        open={openExpireSoon}
        onClose={handleExpireSoonClose}
        closeAfterTransition
        BackdropProps={{
          style: {
            backgroundColor: "rgba(0, 0, 0, 0.1)", // Adjust the color and opacity as needed
            backdropFilter: "none", // This disables the blur effect
          },
        }}
      >
        <Box>
          <SubscriptionExpiringSoon
            date={adminData?.package.expire_date}
            handleClose={handleExpireSoonClose}
          />
        </Box>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openSubscriptionExpierd}
        onClose={handleCloseSubscriptionExpierd}
        closeAfterTransition
      >
        <SubscriptionExpired handleClose={handleCloseBlocked} />
      </Modal>
      <Modal
        open={openBlocked}
        onClose={handleCloseBlocked}
        closeAfterTransition
      >
        <Blocked handleClose={handleCloseBlocked} />
      </Modal>
    </div>
  );
};

export default Home;
