import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import moment from "moment-timezone";

export default function MarketClose({ timeZone }) {
  const [timeDifference, setTimeDifference] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      const now = moment.tz(timeZone);
      const dayOfWeek = now.day(); // Sunday = 0, Monday = 1, ..., Saturday = 6

      if (dayOfWeek >= 1 && dayOfWeek <= 5) {
        const twoAM = now.clone().startOf("day").hour(2);
        const difference = twoAM.diff(now);
        setTimeDifference({
          days: 0,
          hours: Math.floor(moment.duration(difference).asHours()),
          minutes: moment.duration(difference).minutes(),
          seconds: moment.duration(difference).seconds(),
        });
      } else {
        const nextMonday = now
          .clone()
          .startOf("week")
          .add((8 - now.day()) % 7, "days")
          .hour(2);
        const difference = nextMonday.diff(now);
        setTimeDifference({
          days: Math.floor(moment.duration(difference).asDays()),
          hours: Math.floor(moment.duration(difference).asHours()) % 24,
          minutes: moment.duration(difference).minutes(),
          seconds: moment.duration(difference).seconds(),
        });
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [timeZone]);

  const formattedTimeDifference = `${
    timeDifference.days === 0 ? "" : `${timeDifference.days} days`
  } ${timeDifference.hours === 0 ? "" : `${timeDifference.hours} hours`} ${
    timeDifference.minutes === 0 ? "" : `${timeDifference.minutes} minutes`
  }  ${timeDifference.seconds} seconds`;
  return (
    <Box
      sx={{
        position: "fixed",
        top: "0",
        left: "-125px",
        right: "0",
        bottom: "0",
        width: "895px",
        height: "531px",
        overflow: "hidden",
        zIndex: 10000,
      }}
    >
      <div
        style={{
          position: "relative",
          width: "100%",
          height: "10%",
          transform: "rotate(-35deg)",
          display: "flex",
          alignItems: "center",
          backgroundColor: "#FFFFFF",
          color: "#362F22",
          fontSize: "2vw",
          fontWeight: "bold",
          padding: "1vw",
        }}
      >
        <marquee scrollamount="10">
          {timeDifference && (
            <p style={{ marginLeft: "90px" }}>
              Market closed! Opens on {formattedTimeDifference}
            </p>
          )}
        </marquee>
      </div>
    </Box>
  );
}
